import React from 'react'

function termcondition() {
  return (
    <>
      <div className="Privacy_policies">
      <p
          style={{
            margin: '0in',
            marginBottom: '6.0pt',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            marginTop: '20.0pt',
            marginRight: '0in',
            marginLeft: '0in',
            lineHeight: '115%',
          }}
        >
          <h1 className='heading'
            style={{
              fontSize: 27,
              lineHeight: '115%',
              fontFamily: '"Arial",sans-serif',
            }}
          >
            End-User License Agreement
          </h1>
        </p>
        <p id="update">Last updated on 16 January 2023.</p>
          <div class="solid"></div>
  <p>These are our terms and conditions governing the use of Everlove Services and the agreement that operates between you and the <strong>EVER INDUSTRIES LTD </strong>Company. These terms and conditions set out the rights and obligations of all users regarding the use Everlove Services.</p>
  <p>Your access to and use of Everlove Services is conditioned on your acceptance of and compliance with these terms and conditions which apply to all visitors, users and others who access or use the Service. <strong>By accessing or using the Service you agree to be bound by these terms and conditions. If you disagree with any part of these terms and conditions then you may not access the Service. </strong>Please read these terms and conditions carefully before using our Everlove Services.</p>
  <p>Your access to and use of Everlove Services is also conditioned on your acceptance of and compliance with our Privacy Policy which describes our policies and procedures on the collection, use and disclosure of your personal information when you use the application or the website. Please read our Privacy Policy carefully before using our Service.</p>
  <p>Definitions We Use</p>
  <p>For the purposes of these Terms and Service:</p>
  <ul>
    <li><strong><em>Account</em></strong> means a unique account created for you to access our Everlove Services or parts of our Services.</li>
  </ul>
  <ul>
    <li><strong><em>Affiliate</em></strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
  </ul>
  <ul>
    <li><strong><em>Application</em></strong> means the software program named as Everlove which provided by the Company downloaded by you through an Application Store's account to a device.</li>
  </ul>
  <ul>
    <li><strong><em>Application Store</em></strong> means the digital distribution service operated and developed by Apple Inc. (Apple App Store) or Google Inc. (Google Play Store) by which the Application has been downloaded to your device.</li>
  </ul>
  <ul>
    <li><strong><em>Company</em></strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to the <strong>EVER INDUSTRIES LTD </strong></li>
  </ul>
  <ul>
    <li><strong><em>Country</em></strong> refers to (United Kingdom) which is the place of headquarters of our company.</li>
  </ul>
  <ul>
    <li><strong><em>Content</em></strong> refers to content such as text, images, or other information that can be posted, uploaded, linked to or otherwise made available by you, regardless of the form of that content.</li>
  </ul>
  <ul>
    <li><strong><em>Device</em></strong> means any device that can access the Service such as a computer, a cell phone or a digital tablet.</li>
  </ul>
  <ul>
    <li><strong><em>Products</em></strong> refer to the products or items offered for sale on the Service.</li>
  </ul>
  <ul>
    <li><strong><em>Orders</em></strong> mean a request by you to purchase products or services from us.</li>
  </ul>
  <ul>
    <li><strong><em>Service</em></strong> refers to the Website and Application.</li>
  </ul>
  <ul>
    <li><strong><em>Terms of Service </em></strong>(also referred as <strong><em>Terms</em></strong>) mean these terms and conditions that form the entire agreement between you and the <strong>EVER INDUSTRIES LTD </strong>Company regarding the use of the Service.</li>
  </ul>
  <ul>
    <li><strong><em>Third-party Service</em></strong> means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.</li>
  </ul>
  <ul>
    <li><strong><em>Website</em></strong> refers to Everlove accessible from (everlove.uk).</li>
  </ul>
  <ul>
    <li><strong><em>You</em></strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
  </ul>
  <p>User Accounts</p>
  <p><em>Creating an Account</em></p>
  <p>When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.</p>
  <p>By accepting these Terms, you represent that you are over the age of 18 and you can form a binding contract with the <strong>EVER INDUSTRIES LTD </strong>Company. You are authorized to create an account, access or use Everlove Services if:</p>
  <p>(i) You are not a person who is barred from using the Service under the laws of the United States or any other applicable jurisdiction (for example, you do not appear on the U.S. Treasury Department’s list of Specially Designated Nationals or face any other similar prohibition),</p>
  <p>(ii) You will comply with this agreement and all applicable local, state, national and international laws, rules and regulations,</p>
  <p>(iii) you have never been convicted of a felony or indictable offense (or crime of similar severity), a sex crime, or any crime involving violence, and that you are not required to register as a sex offender with any state, federal or local sex offender registry.</p>
  <p>In case of failure to be qualified of all the requirements above, you may not create an account, access or use Everlove Services.</p>
  <p>You are responsible for safeguarding your login credentials that you use to access the Service and for any activities or actions under these credentials, whether your login credentials are with our Service or a Third-Party Service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account. You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.</p>
  <p><em>Modifications on Service and Termination of an Account</em></p>
  <p>We are committed to improve Everlove Services to provide you with new features which increase the effectiveness of your experience. To achieve this goal, we may add or remove some features and if these actions do not materially affect your rights or obligations, we may not provide you with notice before removing them. We may even suspend the Service entirely, in which event we will notify you in advance unless extenuating circumstances, such as safety or security concerns, prevent us from doing so.</p>
  <p>You may terminate your account at any time, for any reason, by following the instructions in "Settings" in the Everlove website or application. However, if you use a third-party payment account such as Apple’s App Store or iTunes Store, as applicable (“App Store”) or the Google Play Store, you will need to manage in app purchases through the settings section of that account to avoid additional billing.</p>
  <p>We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, if you breach these Terms of Service. Upon termination, your right to use the Everlove Services will cease immediately and you will not be entitled to any refund for purchases.</p>
  <p>Content</p>
  <p><em>Your Rights and Obligations</em></p>
  <p>Everlove Services provide you with worldwide, nonexclusive, non-sublicensable, and revocable licence which allows you to access and use features such as posting a content. You retain any and all of your rights to any content you submit, post or display on or through the Service, and you are responsible for protecting those rights also your content including its legality, reliability, and appropriateness.</p>
  <p>This license and access the Service will be automatically revoked in case of the following:</p>
  <ul>
    <li>You use the Everlove Services or any content contained in the Service for any commercial purposes with the exception of our written consent.</li>
  </ul>
  <ul>
    <li>You use any type of manual or automatic device, method or process such as robot, bot, spider, proxy to access, obtain, data mine, or in any way disrupt or damage the structure, presentation, contents, servers or connected networks of the Service.</li>
  </ul>
  <ul>
    <li>You use or develop any third-party applications that interact with the Service or other members' content or information without our written consent.</li>
  </ul>
  <ul>
    <li>You encourage any activity that violates this agreement.</li>
  </ul>
  <p>We reserve the right to investigate, terminate your account and take any available legal action against any illegal or unauthorized uses of the Service.</p>
  <p><em>Rights You Grant Us</em></p>
  <p>By creating an account and posting content on Everlove Services, you grant us a worldwide, transferable, sub-licensable, royalty-free right and license to use, copy, modify, publicly perform, publicly display, reproduce, host, adapt, publish and distribute such content on and through the Service. You agree that this license includes the right for us to make your content available to other users of the Service, who may also use your content subject to these Terms.</p>
  <p>You represent and warrant that:</p>
  <p>(i) The content is yours (you own it) or you have the right to use it and grant us the rights and license as provided in these Terms,</p>
  <p>(ii) the posting of your content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person.</p>
  <p>Although our licence to your content shall be non-exclusive, in the event of your derivative works created through use of the Everlove Services, our licence shall be exclusive. By virtue of the licence, you authorize us to act on your behalf and prevent the infringement of your content taken from Service by other members or third parties.</p>
  <p>Our license to your content is subject to your rights under applicable law and is for the limited purpose of operating, developing, providing, and improving the Everlove Services. You understand and agree that we may monitor or review any content you post as part of a service. In addition, we may delete any content, that in our sole judgment the content violates this Agreement or may harm the reputation of the Service.</p>
  <p>You also agree that any Content you place or that you authorize us to place on the Service may be viewed by other members and may be viewed by any person visiting or participating in the Service.</p>
  <p><em>Content Restrictions and Community Guidelines</em></p>
  <p>The <strong>EVER INDUSTRIES LTD </strong>Company is not responsible for the content of the Service's users. You expressly understand and agree that you are solely responsible for the content and for all activity that occurs under your account, whether done so by you or any third person using your account.</p>
  <p>You may not transmit any content that is unlawful, offensive, upsetting, intended to disgust, threatening, libellous, defamatory, obscene or otherwise objectionable. Examples of such objectionable content include, but are not limited to, the following:</p>
  <ul>
    <li><em>Unlawful or promoting unlawful activity.</em></li>
  </ul>
  <ul>
    <li><em>Defamatory, discriminatory, or mean-spirited content, including references or commentary about religion, race, sexual orientation, gender, national/ethnic origin, or other targeted groups to bully, stalk, intimidate, assault, harass, mistreat</em></li>
  </ul>
  <ul>
    <li><em>Spam, machine – or randomly – generated, constituting unauthorized or unsolicited advertising, chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling.</em></li>
  </ul>
  <ul>
    <li><em>Containing or installing any viruses, worms, malware, trojan horses, or other content that is designed or intended to disrupt, damage, or limit the functioning of any software, hardware or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of a third person.</em></li>
  </ul>
  <ul>
    <li><em>Infringing on any proprietary rights of any party, including patent, trademark, trade secret, copyright, right of publicity or other rights.</em></li>
  </ul>
  <ul>
    <li><em>Impersonating any person or entity including the Company and its employees or representatives.</em></li>
  </ul>
  <ul>
    <li><em>Violating the privacy of any third person.</em></li>
  </ul>
  <ul>
    <li><em>False information and features.</em></li>
  </ul>
  <p>The <strong>EVER INDUSTRIES LTD </strong>Company reserves the right, but not the obligation, to, in its sole discretion, determine whether or not any content is appropriate and complies with this Terms, refuse or remove this content. The <strong>EVER INDUSTRIES LTD </strong>Company further reserves the right to make formatting and edits and change the manner of any content. The <strong>EVER INDUSTRIES LTD </strong>Company can also limit or revoke the use of the Service if you post such objectionable content. As the <strong>EVER INDUSTRIES LTD </strong>Company cannot control all content posted by users and/or third parties on the Service, you agree to use the Service at your own risk. You understand that by using the Service you may be exposed to content that you may find offensive, indecent, incorrect or objectionable, and you agree that under no circumstances will the <strong>EVER INDUSTRIES LTD </strong>Company be liable in any way for any content, including any errors or omissions in any content, or any loss or damage of any kind incurred as a result of your use of any content.</p>
  <p><em>Interaction with Other Users</em></p>
  <p>Although Everlove Services support a respectful user experience that allows members to communicate only after they have both indicated interest in one another Everlove is not responsible for the conduct of any user on or off the Service.</p>
  <p>With these Terms, you agree to be careful in all interactions with other users, particularly if you decide to communicate or meet in person. You also agree that you will not provide your financial information or wire or otherwise send money to other users.</p>
  <p><strong>YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER MEMBERS. YOU UNDERSTAND THAT EVERLOVE SERVICES DOES NOT CONDUCT CRIMINAL BACKGROUND CHECKS ON ITS MEMBERS OR OTHERWISE INQUIRE INTO THE BACKGROUND OF ITS MEMBERS. EVERLOVE SERVICES MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OR COMPATIBILITY OF MEMBERS.</strong></p>
  <p><em>Content Backups</em></p>
  <p>Although regular backups of content are performed, the <strong>EVER INDUSTRIES LTD </strong>Company does not guarantee there will be no loss or corruption of data. Corrupt or invalid backup points may be caused by, without limitation, content that is corrupted prior to being backed up or that change during the time a backup is performed.</p>
  <p>The <strong>EVER INDUSTRIES LTD </strong>Company will provide support and attempt to troubleshoot any known or discovered issues that may affect the backups of content. But you acknowledge that the <strong>EVER INDUSTRIES LTD </strong>Company has no liability related to the integrity of content or the failure to successfully restore content to a usable state. You agree to maintain a complete and accurate copy of any content in a location independent of the Service.</p>
  <p>Purchases</p>
  <p>Everlove Services may offer various products and services for you to purchase (in app purchases) through the App Store, Google Play Store or other payment platforms authorized by us. By choosing to make an in-app purchase, you authorize Everlove Services or third-party services to charge you at the prices displayed for that service, also you accept taxes may be imposed on your payments.</p>
  <p><em>You’re Information</em></p>
  <p>If you wish to purchase a product or service, you may be asked to supply certain information relevant to your purchase including, without limitation, your name, your email, your phone number, your credit / debit card number, the expiration date of your credit / debit card, your billing address, and your shipping information.</p>
  <p>You represent and warrant that:</p>
  <p>(i) You have the legal right to use any credit or debit card(s) or other payment method(s) in connection with any purchase,</p>
  <p>(ii) The information you supply to us is true, correct and complete.</p>
  <p>By submitting such information, you grant us the right to provide the information to payment processing third parties for purposes of facilitating the completion of your purchase.</p>
  <p><em>Auto-Renewal or Automatic Card Payment</em></p>
  <p>When you purchase a periodic subscription through an in-app purchase, you will continue to be billed for the subscription with your payment method until you cancel. After your initial subscription commitment period, and again after any subsequent subscription period, your subscription will automatically continue for an additional equivalent period, at the price you agreed to when subscribing.</p>
  <p>Your objections to payments which are already made should be directed to our customer support. You may also object to a payment by contacting your bank or payment provider. You may unconditionally withdraw your consent to automatic card payments at any time.</p>
  <p>For the termination of your subscription, you will need to log in to your related account and follow the instructions to terminate or cancel your subscription. Even if you have deleted your account or application from your device, you will need to terminate your subscription by contacting us. We will retain all funds charged to you until you terminate or cancel your subscription on Everlove Services. If you terminate or cancel your subscription, you may use your subscription until the end of your then-current subscription term, and your subscription will not be renewed after your then-current term expires.</p>
  <p>If a payment is not successfully settled, due to expiration, insufficient funds, or otherwise, and you do not edit your payment method information, terminate or cancel your subscription, you remain responsible for any uncollected amounts and authorize us to continue billing the payment method, as it may be updated. This may result in a change to your payment billing dates.</p>
  <p>Payment cards (credit cards or debit cards) are subject to validation checks and authorization by your card issuer. If we do not receive the required authorization, we will not be liable for any delay or non-delivery of your purchase.</p>
  <p><em>Refunds</em></p>
  <p>All charges for purchases are non-refundable, and there are no refunds or credits for partially used periods except if a refund for a subscription offering is requested within fourteen days of the transaction date, or if the laws applicable in your jurisdiction provide for refunds.</p>
  <p>For subscribers residing in the EU or European Economic Area (EEA), in accordance with the local law, you are entitled to a full refund without stating the reason, within the 14 days period after the subscription begins.</p>
  <p>For refunds for the purchases made using Apple ID:</p>
  <p>Please submit a request at <a href="https://getsupport.apple.com/">https://getsupport.apple.com</a> .</p>
  <p>For refunds for the purchases made using Google Play Store account:</p>
  <p>Please contact customer support with your order number for the Google Play Store.</p>
  <p>If you use your right of cancellation (except for purchases made through your Apple ID), we will refund (or ask Google to refund) all payments received from you, without undue delay and in any case within 14 days of the date when we received notice of your decision to cancel the agreement. We shall make such refund using the same means of payment as used by you in the initial transaction. In any case, no fees will be charged to you as a result of the refund.</p>
  <p><em>Prices Policy</em></p>
  <p>The <strong>EVER INDUSTRIES LTD </strong>Company reserves the right to revise its prices at any time prior to accepting a purchase.</p>
  <p>The prices quoted may be revised by the <strong>EVER INDUSTRIES LTD </strong>Company subsequent to accepting a purchase in the event of any occurrence affecting delivery caused by government action, variation in customs duties, increased shipping charges, higher foreign exchange costs and any other matter beyond the control of the <strong>EVER INDUSTRIES LTD </strong>Company. In that event, you will have the right to cancel your order.</p>
  <p>Copyright Policy</p>
  <p><em>Intellectual Property Infringement</em></p>
  <p>If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement that is taking place through Everlove Services, you must submit your notice in writing to the attention of our copyright agent via email (iegc103@outlook.com) and include in your notice a detailed description of the alleged infringement. You may be held accountable for damages (including costs and attorneys' fees) for misrepresenting that any content is infringing your copyright.</p>
  <p><em>DMCA Notice and DMCA Procedure for Copyright Infringement Claims</em></p>
  <p>You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):</p>
  <ul>
    <li>An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright's interest.</li>
    <li>A description of the copyrighted work that you claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work.</li>
    <li>Identification of the URL or other specific location on the Service where the material that you claim is infringing is located.</li>
    <li>Your address, telephone number, and email address.</li>
    <li>A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.</li>
    <li>A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.</li>
  </ul>
  <p>You can contact our copyright agent via email (iegc103@outlook.com). Upon receipt of a notification, the <strong>EVER INDUSTRIES LTD </strong>Company will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged content from the Service.</p>
  <p>Our Intellectual Property Rights</p>
  <p>The Everlove Services and its original content, features and functionality are and will remain the exclusive property of the <strong>EVER INDUSTRIES LTD </strong>Company and its licensors. The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the <strong>EVER INDUSTRIES LTD </strong>Company.</p>
  <p>Links to Other Websites</p>
  <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.</p>
  <p>The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
  <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.</p>
  <p>Limitation of Liability</p>
  <p>Notwithstanding any damages that you might incur, the entire liability of the <strong>EVER INDUSTRIES LTD </strong>Company under any provision of this Terms and your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by you through the Services or an amount agreed upon between both parties, if you haven't purchased anything through the Service.</p>
  <p>To the maximum extent permitted by applicable law, in no event shall the <strong>EVER INDUSTRIES LTD </strong>Company or its employees, licensors, affiliates be liable for any special, incidental, indirect, or consequential damages including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms, even if the <strong>EVER INDUSTRIES LTD </strong>Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>
  <p>Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.</p>
  <p><em>AS IS</em> and <em>AS AVAILABLE</em> Disclaimer</p>
  <p>The Everlove Services is provided to you <em>AS IS</em> and <em>AS AVAILABLE</em> and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the <strong>EVER INDUSTRIES LTD </strong>Company, on its own behalf and on behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the <strong>EVER INDUSTRIES LTD </strong>Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</p>
  <p>Without limiting the foregoing, neither the <strong>EVER INDUSTRIES LTD </strong>Company nor any of the company's providers makes any representation or warranty of any kind, express or implied:</p>
  <p>(i) As to the operation or availability of the Service, or the information, content, and materials or products included thereon.</p>
  <p>(ii) That the Service will be uninterrupted or error-free.</p>
  <p>(iii) As to the accuracy, reliability, or currency of any information or content provided through the Service.</p>
  <p>(iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the <strong>EVER INDUSTRIES LTD </strong>Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</p>
  <p>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.</p>
  <p>FTC Affiliate Disclaimer</p>
  <p>The disclosure that follows is intended to fully comply with the Federal Trade Commission's policy of the United States that requires the Company to be transparent about any and all affiliate relations which the Company may have on the Service.</p>
  <p>You should assume that some of the links are "affiliate links", a link with a special tracking code. This means that if you click on an affiliate link and purchase the item, the <strong>EVER INDUSTRIES LTD </strong>Company may receive an affiliate commission. This is a legitimate way to monetize and pay for the operation of the Service and the <strong>EVER INDUSTRIES LTD </strong>Company gladly reveals its affiliate relationships to you.</p>
  <p>The price of the item is the same whether it is an affiliate link or not. Regardless, the <strong>EVER INDUSTRIES LTD </strong>Company only recommends products or services that will add value to users.</p>
  <p>Affiliate advertising programs that the Service uses are:</p>
  <p>(None to date)</p>
  <p>Governing Law</p>
  <p>The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and your use of the Service. Your use of the application may also be subject to other local, state, national, or international laws.</p>
  <p>Disputes Resolution</p>
  <p>If you have any concern or dispute about the Service, you agree to first try to resolve the dispute informally by contacting the <strong>EVER INDUSTRIES LTD </strong>Company.</p>
  <p><em>For European Union (EU) Users</em></p>
  <p>If you are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.</p>
  <p>Changes to These Terms and Conditions</p>
  <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
  <p>By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, in whole or in part, please stop using the Everlove Services.</p>
  <p>Contact Us</p>
  <p>If you have any questions about these Terms of Service, you can contact us:</p>
  <ul style={{ listStyleType: 'undefined', marginLeft: '0in' }}>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif' }}>
              By visiting this page on our website:{' '}
              <span style={{ color: '#4F81BD' }}><a href='https://everlove.uk/'>everlove.uk</a></span>
            </span>
          </li>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif' }}>
              By sending us an email:{' '}
              <span style={{ color: '#4F81BD' }}>
              <a href="mailto:everlove102@outlook.com">everlove102@outlook.com</a>
              </span>
            </span>
          </li>
        </ul>
</div>

    </>
  )
}

export default termcondition
